<template>
  <div :style="`margin-bottom: ${mini ? '1.5rem;' : '0' }`">
    <!-- menu level 1 -->
    <flowy-nav-menu-item
      v-for="(level1Item, level1Index) in menu"
      :key="level1Index"
      :menu-item="level1Item"
      :class="{ 'pr-6': index === 0 && !mini }"
    >
      <template v-if="level1Item.items">
        <!-- menu level 2 -->
        <flowy-nav-menu-item
          v-for="(level2Item, level2Index) in level1Item.items"
          :key="level2Index"
          :menu-item="level2Item"
          subgroup
          small
          :class="{ 'pl-2': !mini }"
        >
          <template v-if="level2Item.items">
            <!-- menu level 3 -->
            <flowy-nav-menu-item
              v-for="(level3Item, level3Index) in level2Item.items"
              :key="level3Index"
              :menu-item="level3Item"
              small
            />
          </template>
        </flowy-nav-menu-item>
      </template>
    </flowy-nav-menu-item>
  </div>
</template>

<script>
import FlowyNavMenuItem from './FlowyNavMenuItem'

/*
|---------------------------------------------------------------------
| Navigation Menu Component
|---------------------------------------------------------------------
|
| Multi-layer navigation menu
|
| menu: [{ text: 'Menu Levels',
|    items: [
|      { text: 'Menu Levels 2.1' },
|      { text: 'Menu Levels 2.2',
|        items: [
|          { text: 'Menu Levels 3.1' },
|          { text: 'Menu Levels 3.2' }
|        ]
|      }
|    ]
|  }]
|
*/
export default {
  components: {
    FlowyNavMenuItem
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    mini: {
      type: Boolean
    },
    menu: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<template>
  <div class="plus-line" style="width: 100%">
    <v-row no-gutters justify="center" :class="{ 'line-css': true, 'default-add': !special, 'special-add': special || inner, 'catch-add': catchType }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div :class="{ 'plus-css': true, 'default-add-plus': !special, 'special-add': special || inner, 'catch-add plus-css-catch-type': catchType }"><div :style="`transform: translate(${single ? '-2px' : '0'}, -3px)`" v-bind="attrs" v-on="on">+</div></div>
        </template>
        <span v-if="inner">{{ tooltip('inner') }}</span>
        <span v-else-if="special">{{ tooltip('special') }}</span>
        <span v-else-if="catchType">{{ tooltip('exception') }}</span>
        <span v-else>{{ tooltip() }}</span>
      </v-tooltip>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    special: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    inner: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    catchType: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    single: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  methods: {
    tooltip(type) {
      switch (type) {
      case 'inner':
        return this.$lang.labels.childStep
      case 'special':
        return this.$lang.labels.query
      case 'exception':
        return this.$lang.labels.exception
      default:
        return this.$lang.labels.step
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.plus-line {
  position: relative;
  height: 22px;
}

.line-css {
  width: 100%;
  height: 1px;
  position: absolute;
  top: 12px;
  left: 0;
}

.plus-css {
  cursor: pointer;
  height: 16px;
  width: 16px;
  position: absolute;
  top: -8px;
  left: 20px;
  font-size: 15px;
  padding-left: 4px;
  border-radius: 3px;
  &-catch-type {
    color: black;
  }
}

.default-add-plus {
  background-color: var(--v-timelineBg-base);
  color: var(--v-textbase-base);
}

.default-add {
  background-color: var(--v-primary-base);
}

.special-add {
  background-color: var(--v-warning-base);
  color: black;
}

.inner-add {
  background-color: var(--v-error-base);
}

.catch-add {
  background-color: var(--v-warning-base);
  color: black;
}
</style>

<template>
  <div>
    <v-list-item
      v-if="!menuItem.items && !menuItem.fullLink && !menuItem.hide"
      :input-value="menuItem.value"
      :to="{ name: menuItem.link, params: { lang: $lang.current_lang } }"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="customDrawerSelect"
      link
      :data-cy="menuItem.text"
      @click="refreshData(menuItem.link)"
    >
      <v-list-item-icon>
        <v-icon :small="small" :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.text }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && defaultFrontend.logServerUrl && menuItem.specKey === 'logsServer'"
      :input-value="menuItem.value"
      :href="defaultFrontend.logServerUrl"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="primary--text"
      link
      target="_blank"
      :data-cy="menuItem.text"
      @click="refreshData(menuItem.link)"
    >
      <v-list-item-icon>
        <v-icon :small="small" :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.text }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && menuItem.specKey === 'serviceDesk'"
      :input-value="menuItem.value"
      href="https://damudo.atlassian.net/servicedesk/"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="primary--text"
      link
      target="_blank"
    >
      <v-list-item-icon>
        <v-icon :small="small" :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.text }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && defaultFrontend.metricServerUrl && menuItem.specKey === 'metricServer'"
      :input-value="menuItem.value"
      :href="defaultFrontend.metricServerUrl"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="primary--text"
      color="primary"
      link
      target="_blank"
      @click="refreshData(menuItem.link)"
    >
      <v-list-item-icon>
        <v-icon :small="small" :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.text }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && defaultFrontend.visualisationServerUrl && menuItem.specKey === 'visualisationServer'"
      :input-value="menuItem.value"
      :href="defaultFrontend.visualisationServerUrl"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="primary--text"
      link
      target="_blank"
      @click="refreshData(menuItem.link)"
    >
      <v-list-item-icon>
        <v-icon :small="small" :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title :class=" {'white--text': $vuetify.theme.dark, 'grey--text ': menuItem.disabled }">
          {{ menuItem.text }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && menuItem.specKey === 'swagger'"
      :input-value="menuItem.value"
      href="/api/swagger-ui/index.html"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="primary--text"
      link
      target="_blank"
    >
      <v-list-item-icon>
        <v-icon :small="small" :class="{ 'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title :class="{ 'custom-nav-color': !$vuetify.theme.dark,'white--text': $vuetify.theme.dark, 'grey--text': menuItem.disabled }">
          {{ menuItem.text }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-group
      v-if="menuItem.items && !menuItem.hide"
      :value="menuItemValue"
      :disabled="menuItem.disabled"
      :sub-group="subgroup"
      :to="menuItem.link"
      link
    >
      <template v-slot:activator>
        <v-list-item-icon v-if="!subgroup">
          <v-icon class="custom-nav-color" :small="small">{{ menuItem.icon || 'mdi-circle-medium' }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="latest-nav-custom-color">
            {{ menuItem.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <slot></slot>

    </v-list-group>
  </div>
</template>

<script>
import { bus } from '@/main'
import { mapGetters, mapState } from 'vuex'
/*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the FlowyNavMenu component
|
*/
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {}
    },
    subgroup: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('app', ['defaultFrontend', 'userSettings']),
    menuItemValue() {
      return this.menuItem.regex ? this.$route.path.includes(this.menuItem.regex) : false
    }
  },
  methods: {
    refreshData(name) {
      bus.$emit('refreshData', name)
    }
  }
}
</script>

<style lang="scss">
.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  line-height: 1.25rem;
}

.latest-nav-custom-color {
  color: var(--v-customNavColor-base) !important;
}
</style>

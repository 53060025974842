import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import JsonViewer from 'vue-json-viewer'

// VUEX - https://vuex.vuejs.org/
import store from './store'

// VUE-ROUTER - https://router.vuejs.org/
import router from './router'

// PLUGINS
import vuetify from './plugins/vuetify'
import './plugins/vue-head'
// import './plugins/vue-gtag'
import Lang from 'vuejs-localization'

Lang.requireAll(require.context('./lang', true, /\.js$/))

import vueDebounce from 'vue-debounce'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/placeholder'
import './filters/trim'
import './filters/formatDate'
import './filters/formatDateHour'
import './filters/formatDateTime'
import './filters/formatDateTimePrecise'
import './filters/formatIsoStart'
import './filters/formatIsoEnd'
import './filters/formatIsoEndFullTime'
import './filters/timeUnits'
import './filters/sizeUnits'
import './filters/javaVariableConventionRules'
import './filters/checkComplexRegex'

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'

import 'animate.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

import auth from '@/auth/index'

import Timeline from './pages/processes/ProcessTimeline'

import DatetimePicker from 'vuetify-datetime-picker'

Vue.component('timeline', Timeline)

Vue.use(Lang)

Vue.use(vueDebounce)

Vue.use(DatetimePicker)

Vue.use(JsonViewer)

import VueMermaid from './components/ui/customVueMermaid'
import { setDomain } from '@/utils/api'
Vue.use(VueMermaid)

const notProtectedRoute = () => {
  return !['login', 'set-password'].includes(router.currentRoute.name)
}

setDomain('/api')

axios.interceptors.request.use((config) => {

  if (!auth.isLoggedIn()) {
    config.headers.common['x-auth-token'] = 'guest'
  } else {
    config.headers.common['x-auth-token'] = auth.getToken()
  }

  return config
})

axios.interceptors.response.use(
  (response) => {
    if (response?.status === 401) auth.logout(notProtectedRoute())

    return response
  },
  (error) => {
    console.log(error?.response)
    if (error?.response?.status === 401) auth.logout(notProtectedRoute())

    return error
  })

export const bus = new Vue()

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export default new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

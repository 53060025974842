<template>
  <v-menu
    offset-y
    nudge-left="165"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        icon
        text
        v-bind="attrs"
        :color="$vuetify.theme.dark ? 'primary' : 'secondary'"
        style="align-self: center"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item class="pb-1">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              text
              large
              v-bind="attrs"
              v-on="on"
              @click="copyStep(item)"
            >
              <v-icon>mdi-vector-combine</v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.actions.copy }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="info"
              text
              large
              v-bind="attrs"
              class="ml-1"
              v-on="on"
              @click="pasteStepBefore(item)"
            >
              <v-icon>mdi-content-paste</v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.actions.pasteBefore }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="success"
              text
              large
              v-bind="attrs"
              class="ml-1"
              v-on="on"
              @click="pasteStepAfter(item)"
            >
              <v-icon>mdi-content-paste</v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.actions.pasteAfter }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="error"
              text
              large
              v-bind="attrs"
              class="ml-1"
              v-on="on"
              @click="pasteStep(item)"
            >
              <v-icon>mdi-content-paste</v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.actions.pasteInstead }}</span>
        </v-tooltip>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        dense
        link
        @click="deleteStep(item)"
      >
        <v-list-item-avatar>
          <v-icon color="error">mdi-trash-can-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>{{ $lang.actions.delete }}</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        dense
        link
        @click="duplicateStep(item)"
      >
        <v-list-item-avatar>
          <v-icon color="primary">mdi-content-duplicate</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>{{ $lang.actions.duplicate }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        dense
        link
        @click="linkToStep(item)"
      >
        <v-list-item-avatar>
          <v-icon color="primary">mdi-link-box-variant-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>{{ $lang.actions.copyLink }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

</template>
<script>
import copy from 'copy-to-clipboard'

export default {
  name: 'StepContextMenu',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    linkToStep(item) {
      if (item && item.localId) {
        const fullPath = window.location.href.split('#')[0] + `#${item.localId}`

        window.location.hash = `${item.localId}`
        copy(fullPath)
      }
    },
    deleteStep(item) {
      this.$emit('deleteStep', item)
    },
    copyStep(item) {
      localStorage.itemStorage = JSON.stringify(item)
      this.showSnack(this.$lang.success.stepCopy)
    },
    showSnack(text) {
      this.$emit('showSnack', text)
    },
    pasteStep(item) {
      this.$emit('pasteStep', item)
    },
    pasteStepBefore(item) {
      this.$emit('pasteStepBefore', item)
    },
    pasteStepAfter(item) {
      this.$emit('pasteStepAfter', item)
    },
    duplicateStep(item) {
      this.$emit('duplicateStep', item)
    }
  }
}
</script>

<template>
  <v-app>
    <layout>
      <router-view />
    </layout>
    <v-snackbar
      v-model="visible"
      :timeout="snackbar?.timeout || 5000"
      :color="snackbar?.color"
      top
      data-cy="snack-content"
    >
      {{ snackbar?.message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="removeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import config from './configs'
import Layout from '@/layouts/Layout'
import {
  getGlobalSettingByIdUsingGET as getSingleSetting,
  getProcessByIdUsingGET as getProcess,
  getGlobalSettingsUsingGET as getSettings,
  getCurrentUserInfoUsingGET as getUser,
  getGlobalSettingBasicUsingGET as getBasicSettings, getCurrentUserInfoUsingGET
} from '@/utils/api'
import { mapActions, mapState, mapGetters } from 'vuex'
import router from '@/router'
import axios from 'axios'
import auth from '@/auth/index'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  components: {
    Layout
  },
  data() {
    return {
      isRouteLoaded: false,
      visible: false
    }
  },
  computed: {
    ...mapGetters('app',['currentSnackbar', 'userRoles']),
    snackbar() {
      return this.currentSnackbar
    }
  },
  watch: {
    $route: {
      handler(val) {
        // console.log(val)
        if (val && val.name && !this.isRouteLoaded) {
          this.isRouteLoaded = true
          this.runRouteChange()
          // console.log(val)
        }
      }
    },
    snackbar: {
      handler(newVal) {
        if (newVal && newVal.message) {
          this.visible = true
          setTimeout(this.removeSnackbar, newVal.timeout || 5000)
        } else {
          this.visible = false
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (auth.isLoggedIn()) {
      if (this.userRoles && this.userRoles.length) {
        const isUserGlobalSettingsAdminOrSuperUser = this.userRoles.some(
          (x) => x.name === 'GLOBAL_SETTING' || x.name === 'SUPER_USER'
        )

        if (isUserGlobalSettingsAdminOrSuperUser) {
          this.getFrontendSetting()
        } else {
          this.getBasicSettingsCall()
        }
      } else {
        this.getBasicSettingsCall()
      }
    }
  },
  created() {
    const lang = localStorage.selectedLanguage
      ? localStorage.selectedLanguage
      : 'en'

    this.$lang.setLang(this.$route.params.lang || lang)

    localStorage.setItem('selectedLanguage', this.$route.params.lang || lang)

    const savedLocationsLoad = localStorage.savedLocations
      ? JSON.parse(localStorage.savedLocations)
      : []

    this.savedLocationsSave(savedLocationsLoad)

    this.checkStaticHash()
  },
  methods: {
    ...mapActions('app', ['defaultFrontend', 'savedLocationsSave', 'removeSnackbar']),
    closeSnackbar() {
      this.visible = false
      this.removeSnackbar()
    },
    runRouteChange() {
      this.$router.beforeEach((to, from, next) => {
        if (to?.name && to.name !== 'error') {
          const savedLocations = localStorage.savedLocations
            ? JSON.parse(localStorage.savedLocations)
            : []
          const savedLocationsPaths = savedLocations.map((x) => x.fullPath)

          this.savedLocationsSave(savedLocations)

          const isFromEdit = from.fullPath.split('/').includes('edit')
          const isToEdit = to.fullPath.split('/').includes('edit')
          const isFromInSaved = savedLocationsPaths.includes(from.fullPath)
            ? savedLocationsPaths.indexOf(from.fullPath)
            : -1
          const isToInSaved = savedLocationsPaths.includes(to.fullPath)
            ? savedLocationsPaths.indexOf(from.fullPath)
            : -1

          if (isFromEdit)
            this.handleEditCreate(savedLocations, from, isFromInSaved)

          if (isToEdit) this.handleEditCreate(savedLocations, to, isToInSaved)
          // eslint-disable-next-line callback-return
          next()
        } else {
          // eslint-disable-next-line callback-return
          next()
        }
        // this.checkStaticHash()
      })
    },
    iconHandler(type, subType) {
      switch (type) {
      case 'processes':
        return 'mdi-console-network'
      case 'cron':
        return 'mdi-briefcase-clock-outline'
      case 'rest':
        return 'mdi-web'
      case 'credentials':
        return 'mdi-lock'
      case 'templates':
        return 'mdi-text-box-outline'
      case 'translations':
        return 'mdi-text'
      case 'validations':
        return 'mdi-magnify-scan'
      case 'plugins':
        return 'mdi-puzzle-outline'
      case 'modules':
        return 'mdi-briefcase-edit-outline'
      case 'local':
        return 'mdi-briefcase-edit-outline'
      case 'users':
        return 'mdi-account'
      case 'roles':
        return 'mdi-account-group-outline'
      case 'history':
        return 'mdi-history'
      case 'settings':
        if (subType === 'FRONTEND') return 'mdi-flip-to-front'
        if (subType === 'INSTANCE') return 'mdi-flip-to-back'
        if (subType === 'PROCESS') return 'mdi-flip-vertical'
        if (subType === 'PLUGIN') return 'mdi-puzzle-star-outline'

        return 'mdi-cog'
      case 'libraries':
        return 'mdi-library-outline'
      case 'storage':
        return 'mdi-cube'
      case 'event-handler':
        return 'mdi-briefcase-outline'
      case 'entities':
        return 'mdi-database-settings-outline'
      case 'messaging':
        return 'mdi-message-text-outline'
      default:
        return 'mdi-home'
      }
    },
    handleEditCreate(savedLocations, editObject, isInSavedIndex) {
      let savedLocationsInner = savedLocations

      const resourcesTypes = {
        translations: 'translation',
        modules: 'module',
        local: 'module',
        processes: 'process',
        credentials: 'process-credential',
        templates: 'text-template',
        cron: 'trigger-cron',
        rest: 'trigger-rest',
        validations: 'validation-rule',
        plugins: 'plugin',
        settings: 'setting',
        users: 'user',
        roles: 'role',
        history: 'history',
        hub: 'hub/module',
        libraries: 'library',
        storage: 'storage',
        'event-handler': 'trigger-event-handler',
        entities: 'entity',
        messaging: 'trigger-messaging'
      }

      const isNestedRoute = (routeName) => {
        const nestedRoutes = [
          'triggers',
          'permissions',
          'templates',
          'modules',
          'hub'
        ]

        return nestedRoutes.includes(routeName)
      }

      const isStorage = editObject.fullPath.split('/')[2] === 'storage'

      if (isInSavedIndex !== -1) {
        savedLocationsInner.sort((x, y) => {
          return x === savedLocationsInner[isInSavedIndex]
            ? -1
            : y === savedLocationsInner[isInSavedIndex]
              ? 1
              : 0
        })
        localStorage.savedLocations = JSON.stringify(savedLocationsInner)
        this.savedLocationsSave(savedLocationsInner)
      } else {
        let axiosUrl = `/api/${
          resourcesTypes[
            isNestedRoute(editObject.fullPath.split('/')[2])
              ? editObject.fullPath.split('/')[3]
              : editObject.fullPath.split('/')[2]
          ]
        }/${editObject.params.id}`

        if (editObject.query) {
          const queries = Object.keys(editObject.query).map(
            (key) => `${key}=${editObject.query[key]}`
          )

          axiosUrl += `?${queries.join('&')}`
        }

        if (axiosUrl && !axiosUrl.includes('undefined')) {
          axios.get(axiosUrl).then((res) => {
            if (res.status === 404) {
              return
            }
            if (isStorage) {
              getProcess({ id: res.data?.data?.processId })
                .then((res2) => {
                  savedLocationsInner.unshift({
                    fullPath: editObject.fullPath,
                    name: `${res2.data?.data?.name}: ${res.data?.data?.key}`,
                    icon: this.iconHandler(
                      isNestedRoute(editObject.fullPath.split('/')[2])
                        ? editObject.fullPath.split('/')[3]
                        : editObject.fullPath.split('/')[2],
                      res.data?.data?.type
                    )
                  })
                  savedLocationsInner = savedLocationsInner.filter(
                    (value, index, self) =>
                      index ===
                      self.findIndex((t) => t.fullPath === value.fullPath)
                  )
                  localStorage.savedLocations = JSON.stringify(
                    savedLocationsInner.slice(0, 15)
                  )
                  this.savedLocationsSave(savedLocationsInner.slice(0, 15))
                })
                .catch((error) => {
                  console.log(error)
                })
            } else {
              savedLocationsInner.unshift({
                fullPath: editObject.fullPath,
                name: res.data?.data?.name || res.data?.data?.resourceName,
                icon: this.iconHandler(
                  isNestedRoute(editObject.fullPath.split('/')[2])
                    ? editObject.fullPath.split('/')[3]
                    : editObject.fullPath.split('/')[2],
                  res.data?.data?.type
                )
              })
              savedLocationsInner = savedLocationsInner.filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.fullPath === value.fullPath)
              )
              localStorage.savedLocations = JSON.stringify(
                savedLocationsInner.slice(0, 15)
              )
              this.savedLocationsSave(savedLocationsInner.slice(0, 15))
            }

            savedLocationsInner = savedLocationsInner.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.fullPath === value.fullPath)
            )
            localStorage.savedLocations = JSON.stringify(
              savedLocationsInner.slice(0, 15)
            )
            this.savedLocationsSave(savedLocationsInner.slice(0, 15))
          })
        }
      }
    },
    getFrontendSetting() {
      getSettings({ type: 'FRONTEND' })
        .then((res) => {
          if (res.status !== 200) {
            this.getBasicSettingsCall()

            return
          }

          const defaultFe = res.data.data.find((x) => x.type === 'FRONTEND')

          if (defaultFe) {
            getSingleSetting({ id: defaultFe.id })
              .then((res2) => {
                this.defaultFrontend(res2.data.data.values)
                document.title = res2?.data?.data?.values?.environmentName ? `${res2?.data?.data?.values?.environmentName} - Flowy` : 'Flowy'
              })
              .catch((err) => {
                this.err = err
              })
          }

        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    getBasicSettingsCall() {
      getBasicSettings()
        .then((res2) => {
          if (res2.status === 200) {
            this.defaultFrontend(res2.data.data)
            document.title = res2?.data?.data?.environmentName ? `${res2?.data?.data?.environmentName} - Flowy` : 'Flowy'
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    checkStaticHash() {
      fetch(
        process.env.NODE_ENV === 'development'
          ? '/version.txt'
          : '/static/version.txt'
      )
        .then((r) => r.json())
        .then((staticHash) => {
          if (staticHash) {
            if (!localStorage.frontendVersion) {
              localStorage.frontendVersion = staticHash
              window.location.reload(true)
            } else if (Number(localStorage.frontendVersion) !== staticHash) {
              localStorage.frontendVersion = staticHash
              window.location.reload(true)
            }
          }
        })
        .catch(() => {
          if (localStorage.frontendVersion !== 'null') {
            localStorage.frontendVersion = 'null'
            window.location.reload(true)
          } else {
            localStorage.frontendVersion = 'null'
          }
        })
      fetch(
        process.env.NODE_ENV === 'development'
          ? '/buildtime.txt'
          : '/static/buildtime.txt'
      )
        .then((r) => r.text())
        .then((buildtime) => {
          const year = buildtime.substring(0, 4)
          const month = buildtime.substring(4, 6)
          const day = buildtime.substring(6, 8)
          const hour = buildtime.substring(9, 11)
          const minute = buildtime.substring(11, 13)
          const second = buildtime.substring(13, 15)

          const buildtimeDate = new Date(
            year,
            Number(month) - 1,
            day,
            hour,
            minute,
            second
          ).toISOString()

          if (buildtimeDate) {
            if (!localStorage.frontendBuildtime) {
              localStorage.frontendBuildtime = buildtimeDate
              window.location.reload(true)
            } else if (localStorage.frontendBuildtime !== buildtimeDate) {
              localStorage.frontendBuildtime = buildtimeDate
              localStorage.removeItem('preFillData')
              window.location.reload(true)
            }
          }
        })
        .catch(() => {
          if (localStorage.frontendVersion !== 'null') {
            localStorage.frontendVersion = 'null'
            window.location.reload(true)
          } else {
            localStorage.frontendVersion = 'null'
          }
        })
      fetch('/api/actuator/info')
        .then((r) => r.json())
        .then((result) => {
          if (result && result.git) {
            if (!localStorage.backendVersion) {
              localStorage.backendVersion = JSON.stringify(result.git)
              window.location.reload(true)
            } else if (
              localStorage.backendVersion !== JSON.stringify(result.git)
            ) {
              localStorage.backendVersion = JSON.stringify(result.git)
              window.location.reload(true)
            }
          }
        })
        .catch(() => {
          console.log('Err')
        })

      let userLocal = localStorage.getItem('userData')
        ? JSON.parse(localStorage.getItem('userData'))
        : null

      if (userLocal) {
        getUser()
          .then((res) => {
            if (res.status === 200) {
              if (res.data.data) {
                userLocal = {
                  ...userLocal,
                  roles: res.data.data.roles
                }
                localStorage.setItem('userData', JSON.stringify(userLocal))
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }
}
</script>

import { render, staticRenderFns } from "./Mover.vue?vue&type=template&id=56bbf896&scoped=true"
import script from "./Mover.vue?vue&type=script&lang=js"
export * from "./Mover.vue?vue&type=script&lang=js"
import style0 from "./Mover.vue?vue&type=style&index=0&id=56bbf896&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56bbf896",
  null
  
)

export default component.exports